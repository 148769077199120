import React  from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../../../../images/crown.svg'
import defaultTheme from '../../../../../../themes/default'

const Wrapper = styled.div`
  padding: 0.5em 1em;
  width: calc(50% - 1em);
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  svg {
    width: 2em;
    height: 2em;
    margin-top: -0.1em;

    * {
      fill: ${defaultTheme.color.text};
    }
  }
`

const Highscore = ({ entries, onHighscoreClick, ...props }) => (
  <Wrapper {...props}>
    <Icon onClick={onHighscoreClick} />
  </Wrapper>
)

export default Highscore
