import styled from 'styled-components'
import defaultTheme from "../../../../themes/default";

const Card = styled.div`
  position: absolute;
  bottom: 1em;
  left: 1em;
  right: 1em;
  background-color: ${defaultTheme.color.textInverted};
  padding: 1em;
  z-index: 2;
  border-radius: 1em;
  box-shadow: 2px 5px 20px rgba(0, 0, 0, .2);
`

export default Card
