import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../../../../images/poop-marker.svg'
import defaultTheme from '../../../../../../themes/default'

const Wrapper = styled.div`
  width: 5em;
  transform: translate(-50%, -100%);
  position: relative;
  z-index: ${props => props.unread ? '1' : 'initial'};

  &:before {
    content: ${props => props.unread ? '" "' : 'none'};
    position: absolute;
    top: -5%;
    right: 10%;
    height: 1.5em;
    width: 1.5em;
    z-index: 1;
    background-color: orange;
    border-radius: 50%;
    display: block;
  }

  svg {
    fill: ${defaultTheme.color.textInverted};
    filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.4));
    cursor: pointer;
    pointer-events: none;
    position: relative;

    path,
    circle {
      pointer-events: initial;
    }

    .st0 {
      fill: ${defaultTheme.color.primary};
    }
  }
`

const Marker = ({ onClick, unread = false, ...props }) => (
  <Wrapper {...props} unread={unread}>
    <Icon onClick={onClick}/>
  </Wrapper>
)

export default Marker
