import React from 'react'
import DefaultView from './views/Default'
import './index.css'
import OneSignalProvider from './provider/OneSignalProvider'

function App() {
  return <OneSignalProvider>{({ saveName }) => <DefaultView saveName={saveName} />}</OneSignalProvider>
}

export default App
