import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../../../../images/poop-marker.svg'
import defaultTheme from '../../../../../../themes/default'

const Wrapper = styled.div`
  width: 5em;
  transform: translate(-50%, -100%);
  position: relative;
  z-index: ${props => props.unread ? '1' : 'initial'};

  &:before {
    content: ${props => props.unread ? 'attr(data-unread)' : 'none'};
    position: absolute;
    top: -5%;
    right: 10%;
    height: 1.5em;
    width: 1.5em;
    z-index: 1;
    background-color: orange;
    border-radius: 50%;
    color: ${defaultTheme.color.textInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${defaultTheme.font.primary};
    
  }

  svg {
    filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.4));

    * {
      fill: ${defaultTheme.color.textInverted};
    }
  }
`

const Number = styled.span`
  position: absolute;
  top: .2em;
  left: 0;
  right: 0;
  text-align: center;
  color: ${defaultTheme.color.primary};
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.black};
  font-size: 2.3em;
`

const ClusterMarker = ({ amount, unread, ...props }) => (
  <Wrapper {...props} unread={unread} data-unread={unread}>
    <Icon />
    <Number>{amount}</Number>
  </Wrapper>
)

export default ClusterMarker
