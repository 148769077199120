import styled from 'styled-components'
import React from 'react'
import { ReactComponent as Icon } from '../../../../../../images/trash.svg'
import defaultTheme from '../../../../../../themes/default'

const Button = styled.button`
  height: 3em;
  width: 3em;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 1em;
  bottom: 1em;

  svg {
    fill: ${defaultTheme.color.textSecondary};
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Remove = ({ claps = [], ...props }) => (
  <Wrapper {...props}>
    <Button>
      <Icon />
    </Button>
  </Wrapper>
)

export default Remove
