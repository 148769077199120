import { Component } from 'react'

class OneSignalProvider extends Component {
  componentDidMount() {
    this.oneSignalInit()
  }

  oneSignalInit = async () => {
    if (!window.OneSignal) {
      return
    }
    window.OneSignal.init({
      appId: '5dec2f2f-0d22-4d91-8f4e-772c5a2548f3',
      //  autoRegister: true,
      //  autoResubscribe: true,
      //  notifyButton: {
      //    enable: true,
      //  },
    })
  }

  saveName = async (name) => {
    if (!window.OneSignal) {
      return
    }
    window.OneSignal.push(function () {
      window.OneSignal.sendTag('real_name', name)
    })
  }

  render() {
    return this.props.children({ saveName: this.saveName })
  }
}

export default OneSignalProvider
