import styled from 'styled-components'
import React from 'react'
import AddPoop from './components/AddPoop'
import TimeSelect from "./components/TimeSelect";
import Highscore from "./components/Highscore";

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 1em;
  left: 1em;
  right: 1em;
  display: flex;
  justify-content: center;
`

const BottomBar = styled.div`
  display: flex;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .8), rgba(255, 255, 255, .95));
  border-radius: .5em;
  position: absolute;
  justify-content: space-between;
  left: 50%;
  bottom: 0;
  width: 77%;
  min-width: 240px;
  max-width: 320px;
  transform: translateX(-50%);
  pointer-events: initial;
`

const BottomLine = ({ onAddClick, onHighscoreClick, onTimeChange, timeSelections, selectedTime, ...props }) => (
  <Wrapper {...props}>
    <AddPoop onClick={onAddClick} />
    <BottomBar>
      <TimeSelect onTimeChange={onTimeChange} timeSelections={timeSelections} selectedTime={selectedTime} />
      <Highscore onHighscoreClick={onHighscoreClick} />
    </BottomBar>
  </Wrapper>
)

export default BottomLine
