import styled from 'styled-components'
import React from 'react'
import { ReactComponent as Icon } from '../../../../../../images/add_poop.svg'
import defaultTheme from "../../../../../../themes/default";

const Wrapper = styled.button`
  height: 4em;
  width: 4em;
  padding: .8em;
  box-sizing: border-box;
  border: 0;
  background:  ${defaultTheme.color.primary};
  border-radius: 50%;
  pointer-events: initial;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-bottom: 1em;
  box-shadow: 0 3px 20px rgba(0, 54, 204, .3);
  
  svg * {
    fill: ${defaultTheme.color.textInverted};
  }
`

const AddPoop = ({ ...props }) => (
  <Wrapper {...props}>
    <Icon />
  </Wrapper>
)

export default AddPoop
