import styled from 'styled-components'
import React from 'react'
import { ReactComponent as Icon } from '../../../../../../images/clap.svg'
import defaultTheme from '../../../../../../themes/default'


const Button = styled.button`
  height: 4em;
  width: 4em;
  flex-shrink: 0;
  padding: 0.8em;
  box-sizing: border-box;
  border: 0;
  background: ${defaultTheme.color.primary};
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  svg {
    fill: ${defaultTheme.color.textInverted};
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  opacity: ${props => props.disabled ? .5 : 1};
  
  ${Button} {
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
  }
`

const Infos = styled.span`
  font-family: ${defaultTheme.font.primary};
  color: ${defaultTheme.color.textSecondary};
  font-size: ${defaultTheme.fontSize.small};
  margin-left: 0.5em;

  strong {
    font-weight: ${defaultTheme.fontWeight.bold};
    color: ${defaultTheme.color.text};
  }
`

const getInfo = (claps) => {
  const ammount = claps.length
  const eachPerson = claps.reduce((accumulator, clap) => {
    let a = accumulator[clap]
    accumulator[clap] = typeof a === 'undefined' ? 1 : a + 1
    return accumulator
  }, {})
  const eachPersonString = Object.keys(eachPerson).map((key, i) => {
    let toReturn = `${key}[${eachPerson[key]}]`
    if (i + 1 !== Object.keys(eachPerson).length) {
      toReturn += ', '
    }
    return toReturn
  })
  const toReturn = [<strong key={1}>{ammount} Respekt{ammount === 1 ? '' : 's'}</strong>]
  if (claps.length) {
    toReturn.push([<span key={2}> von {eachPersonString}</span>])
  }
  return toReturn
}

const Clap = ({ claps = [], ...props }) => (
  <Wrapper {...props}>
    <Button>
      <Icon />
    </Button>
    <Infos>{getInfo(claps)}</Infos>
  </Wrapper>
)

export default Clap
