import React from 'react'
import DisplayDefaultView from './display'
import fetch from 'node-fetch'
import { API_URL } from '../../data'

const getTimeSelections = (from) => {
  const now = new Date()
  const toReturn = []

  for (let year = now.getFullYear(); year >= from.getFullYear(); year--) {
    toReturn.push({
      from: new Date(year + '-01-01').getTime(),
      to: new Date(year + 1 + '-01-01').getTime() - 1,
      name: year,
    })
  }

  return toReturn
}

const timeSelections = [
  {
    from: 0,
    to: new Date().getTime(),
    name: 'Alle',
  },
  ...getTimeSelections(new Date('2020-01-01')),
]

class ContainerDefaultView extends React.Component {
  state = {
    places: [],
    selectedTime: 1,
  }

  componentDidMount() {
    this.fetchItems()
    this.interval = setInterval(this.fetchItems, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getFilterPlaces = () =>
    this.state.places.filter((entry) => {
      const entryDate = new Date(entry.date)
      const {from, to} = timeSelections[this.state.selectedTime]
      return entryDate > from && entryDate < to
    })

  fetchItems = async () => {
    const response = await fetch(API_URL + 'list', {})
    const json = await response.json()
    const modJson = json.map(({ lat, lng, ...rest }) => ({ lat: lat * 1, lng: lng * 1, ...rest }))
    this.setState({ places: modJson })
  }

  onClap = async (params) => {
    try {
      await fetch(API_URL + 'like', {
        method: 'POST',
        body: JSON.stringify(params),
        mode: 'no-cors',
      })
    } catch (e) {
      console.log(e)
    }
    this.fetchItems()
  }

  onRemove = async (params) => {
    try {
      await fetch(API_URL + 'remove', {
        method: 'POST',
        body: JSON.stringify(params),
        mode: 'no-cors',
      })
    } catch (e) {
      console.log(e)
    }
    this.fetchItems()
  }

  putItem = async (params) => {
    if (window.location.hostname === 'localhost') {
      params.push = false
    }
    try {
      await fetch(API_URL + 'save', {
        method: 'POST',
        body: JSON.stringify(params),
        mode: 'no-cors',
      })
    } catch (e) {
      console.log(e)
    }
    this.fetchItems()
  }

  handleTimeChange = (index) => {
    this.setState({selectedTime: index})
  }

  render() {
    const { ...props } = this.props
    return (
      <DisplayDefaultView
        places={this.getFilterPlaces()}
        putItem={this.putItem}
        onClap={this.onClap}
        onRemove={this.onRemove}
        onTimeChange={this.handleTimeChange}
        timeSelections={timeSelections}
        selectedTime={this.state.selectedTime}
        {...props}
      />
    )
  }
}

export default ContainerDefaultView
