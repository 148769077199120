import React, { useState } from 'react'
import styled from 'styled-components'
import defaultTheme from '../../../../themes/default'
import Card from '../Card'
import { ReactComponent as Icon } from '../../../../images/crown.svg'
import Tabs from './components/Tabs'

const StyledCard = styled(Card)`
  bottom: calc(4em - 1px);
  z-index: 0;
  top: 1em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Close = styled.span`
  position: absolute;
  top: 0.2em;
  right: 0.5em;
  color:rgba(0, 0, 0, 0.6);
  font-size: 1.5em;
  font-family: ${defaultTheme.font.primary};
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  width: 1.1em;
  top: 0;
  left: 0;

  > * {
    fill: ${defaultTheme.color.primary};
  }
`

const ListWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
`

const List = styled.table`
  font-family: ${defaultTheme.font.primary};
  border-collapse: collapse;
  width: 100%;

  tr:first-child {
    background-color: rgba(0, 0, 0, 0.05);

    td:first-child {
      font-size: 2em;
    }
  }

  td {
    &:nth-child(1) {
      font-size: 1.5em;
      color: ${defaultTheme.color.primary};
      text-align: center;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }

    &:nth-child(2) {
      position: relative;
      width: 100%;
      font-size: 1.1em;
    }

    &:nth-child(3) {
      color: ${defaultTheme.color.text};
      text-align: right;
      padding-right: .5em;
    }
  }
`

const Item = styled.tr``

const getItems = (entries, order) => {
  const itemsObj = entries.reduce((rv, entry) => {
    rv[entry.name] = rv[entry.name] || { amount: 0, likes: 0 }
    rv[entry.name].amount += 1
    rv[entry.name].likes += entry.likes ? entry.likes.length : 0
    return rv
  }, {})
  const itemsArray = Object.keys(itemsObj).map((name) => ({
    name,
    likes: itemsObj[name].likes,
    amount: itemsObj[name].amount,
  }))
  return itemsArray.sort((itemA, itemB) => {
    const x = itemA[order]
    const y = itemB[order]
    return x < y ? 1 : x > y ? -1 : 0
  })
}

const tabItems = [
  {
    name: 'Sessions',
    value: 'amount',
  },
  {
    name: 'Likes',
    value: 'likes',
  },
]

const HighscoreCard = ({ entries, onClose, ...props }) => {
  const [order, setOrder] = useState(tabItems[0].value)
  const [activeTag, setActiveTab] = useState(0)
  return (
    <StyledCard {...props}>
      <Wrapper>
        <Tabs
          items={tabItems}
          onClick={(index) => {
            setActiveTab(index)
            setOrder(tabItems[index].value)
          }}
          active={activeTag}
        />
        <ListWrapper>
          <List>
            <tbody>
              {getItems(entries, order).map(({ name, ...rest }, index) => (
                <Item key={name + index}>
                  <td>{index + 1}</td>
                  <td>
                    {name}
                    {!index && <StyledIcon />}
                  </td>
                  <td>{rest[order]}</td>
                </Item>
              ))}
            </tbody>
          </List>
        </ListWrapper>
        <Close onClick={onClose}>✖</Close>
      </Wrapper>
    </StyledCard>
  )
}

export default HighscoreCard
