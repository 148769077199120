import React from 'react'
import styled from 'styled-components'
import Map from './components/Map'
import BottomLine from './components/BottomLine'
import ViewCard from './components/ViewCard'
import CreateCard from './components/CreateCard'
import Cookies from 'js-cookie'
import HighscoreCard from './components/HighscoreCard'

const COOKIE_NAME = 'fo_name'
const COOKIE_READ = 'fo_read'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

class DisplayDefaultView extends React.Component {
  state = {
    showInfo: -1,
    shouldAdd: false,
    showHighscore: false,
    createMarkerPos: {
      lat: 0,
      lng: 0,
    },
    name: '',
    intPosition: false,
  }

  mapRef = React.createRef()

  handleAddClick = async () => {
    let getLoc = await this.getGeoLoc()
    if (!getLoc) {
      getLoc = this.mapRef.current.getCenterLoc()
    } else {
    }
    this.setState({
      showInfo: -1,
      shouldAdd: true,
      showHighscore: false,
      createMarkerPos: getLoc,
    })
    this.mapRef.current.setCenter()
  }

  handleHighscoreClick = () => {
    this.setState({
      showHighscore: !this.state.showHighscore,
    })
  }

  handlePlaceClick = (key) => () => {
    if (this.state.shouldAdd) return
    this.saveReadCookie(key)
    this.setState({ showInfo: key })
  }

  handleMapClick = (value) => {
    if (!this.state.shouldAdd) return
    this.setState({
      createMarkerPos: {
        lat: value.lat,
        lng: value.lng,
      },
    })
  }

  handleSave = (...a) => {
    this.props.putItem(...a)
    this.setState({ shouldAdd: false })
  }

  handleClose = () => {
    this.setState({ showInfo: -1, shouldAdd: false, showHighscore: false })
  }

  readNameFromCookies = () => {
    if (Cookies.get(COOKIE_NAME)) {
      this.setState({
        name: Cookies.get(COOKIE_NAME),
      })
      return true
    }
    return false
  }

  promptName = () => {
    const name = window.prompt('Bitte gebe deinen Namen ein')
    if (name === null || name === '') {
      this.promptName()
      return
    }
    Cookies.set(COOKIE_NAME, name, { expires: 365 })
    this.setState({
      name,
    })
  }

  getGeoLoc = async () => {
    if (!navigator.geolocation) {
      return false
    }
    try {
      const { coords } = await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject),
      )
      return {
        lat: coords.latitude,
        lng: coords.longitude,
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  firstCookieLookup = () => {
    if (!Cookies.get(COOKIE_READ)) {
      const activePlaces = this.props.places.map((place) => place.id)
      Cookies.set(COOKIE_READ, activePlaces.join(','), { expires: 60 })
    }
  }

  saveReadCookie = (id) => {
    const read = Cookies.get(COOKIE_READ)
    if (read.includes(id)) {
      return
    }
    const newActivePlaces = read + ',' + id
    Cookies.set(COOKIE_READ, newActivePlaces, { expires: 60 })
  }

  mapReadStatus = (places) => {
    const readCookie = Cookies.get(COOKIE_READ) || ''
    return places.map((place) => ({
      ...place,
      unread: !readCookie.includes(place.id),
    }))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.name !== this.state.name) {
      this.props.saveName(this.state.name)
    }
    if (JSON.stringify(prevProps.places) !== JSON.stringify(this.props.places) && !this.state.initPos) {
      this.firstCookieLookup()
      this.mapRef.current.setCenter()
      this.setState({ initPos: true })
    }
  }

  componentDidMount() {
    if (!this.readNameFromCookies()) {
      this.promptName()
    }
  }

  render() {
    const { places, putItem, saveName, onClap, onRemove, onTimeChange, timeSelections, selectedTime, ...props } = this.props
    let showPlaces = this.state.shouldAdd
      ? [{ ...this.state.createMarkerPos, clickable: false, unread: false }]
      : this.mapReadStatus(places)
    const activePlace = places.find(({ id }) => id === this.state.showInfo)
    return (
      <Wrapper {...props}>
        <Map
          places={showPlaces}
          onPlaceClick={this.handlePlaceClick}
          onMapClick={this.handleMapClick}
          ref={this.mapRef}
        />
        <BottomLine
          onAddClick={this.handleAddClick}
          onHighscoreClick={this.handleHighscoreClick}
          onTimeChange={onTimeChange}
          timeSelections={timeSelections}
          selectedTime={selectedTime}
        />
        {activePlace && (
          <ViewCard {...activePlace} onClose={this.handleClose} onClap={onClap} onRemove={onRemove} clapName={this.state.name} />
        )}
        {this.state.shouldAdd && (
          <CreateCard
            markerPos={this.state.createMarkerPos}
            onSave={this.handleSave}
            onClose={this.handleClose}
            name={this.state.name}
          />
        )}
        {this.state.showHighscore && <HighscoreCard onClose={this.handleClose} entries={places} />}
      </Wrapper>
    )
  }
}

DisplayDefaultView.defaultProps = {
  places: [],
  putItem: () => {},
  saveName: () => {},
}

export default DisplayDefaultView
