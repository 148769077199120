import React  from 'react'
import styled from 'styled-components'
import defaultTheme from '../../../../../../themes/default'
import { ReactComponent as ArrowDown } from '../../../../../../images/arrow_down.svg'

const Wrapper = styled.div`
  position: relative;
  font-size: ${defaultTheme.fontSize.text};
  font-family: ${defaultTheme.font.primary};
  color: ${defaultTheme.color.primary};
  color: ${defaultTheme.color.text};
  padding: 0.5em 1em .5em 1.75em;
  line-height: 1.5;
  width: calc(50% - 1em);
  box-sizing: border-box;

  svg {
    position: absolute;
    left: .5em;
    width: 1.25em;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    * {
      fill: ${defaultTheme.color.primary};
      fill: ${defaultTheme.color.text};
    }
  }
`

const Select = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const TimeSelect = ({ onTimeChange, timeSelections, selectedTime, ...props }) => {
  return (
    <Wrapper {...props}>
      {timeSelections[selectedTime].name}
      <Select
        onChange={(e) => {
          onTimeChange(e.target.value)
        }}
        value={selectedTime}
      >
        {timeSelections.map((selection, index) => (
          <option key={index} value={index}>
            {selection.name}
          </option>
        ))}
      </Select>
      <ArrowDown />
    </Wrapper>
  )
}

export default TimeSelect
