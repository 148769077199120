import React from 'react'
import styled from 'styled-components'
import defaultTheme from '../../../../../../themes/default'

const Wrapper = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid ${defaultTheme.color.primary};
  font-family: ${defaultTheme.font.primary};
  margin: .5em 0 1em;
  cursor: default;
  height: 2em;
`

const Item = styled.li`
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => (props.active ? defaultTheme.color.primary : defaultTheme.color.text)};
  font-size: ${(props) => (props.active ? defaultTheme.fontSize.big : defaultTheme.fontSize.text)};
  transition: all .1s ease-out;
`

const Tabs = ({ items, active, onClick, ...props }) => (
  <Wrapper {...props}>
    {items.map(({ name, value }, index) => (
      <Item key={value} onClick={() => onClick(index)} active={active === index}>
        {name}
      </Item>
    ))}
  </Wrapper>
)

export default Tabs
