import React from 'react'
import styled from 'styled-components'
import defaultTheme from '../../../../themes/default'
import Card from '../Card'
import ImageUpload from '../ViewCard/components/ImageUpload'

const Wrapper = styled.form``

const Title = styled.h1`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.black};
  font-size: ${defaultTheme.fontSize.big};
  color: ${defaultTheme.color.text};
  text-align: center;
  margin: 0;
  padding-bottom: 0.5em;
`

const ButtonLine = styled.div`
  display: flex;
  //justify-content: stretch;
`

const Label = styled.label`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.black};
  font-size: ${defaultTheme.fontSize.small};
  color: ${defaultTheme.color.text};
`

const Input = styled.input`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.text};
  border: 0;
  background: none;
  border-bottom: 2px ${defaultTheme.color.text} solid;
  margin-bottom: 1em;
  width: 100%;
  outline: none;
  transition: border-bottom-color 0.2s ease-in-out;

  &:focus {
    border-bottom-color: ${defaultTheme.color.primary};
  }

  &::placeholder {
    color: ${defaultTheme.color.textSecondary};
  }

  &:disabled {
    opacity: 0.5;
  }
`

const Textarea = styled(Input.withComponent('textarea'))``

const ColorPicker = styled.input`
  margin: 0 0 0.5em 0;
  display: block;
  padding: 0;
  appearance: none;
  border: 0;
  width: 100%;
  background: transparent;
  font-size: ${defaultTheme.fontSize.text};
  height: 2em;
`

const Submit = styled.input`
  background: ${defaultTheme.color.primary};
  border: 0;
  display: block;
  border-radius: 50em;
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.textInverted};
  padding: 0.5em;
  margin: 0 0.2em;
  flex: 1;
`

const Abort = styled(Submit)`
  //border: 2px solid ${defaultTheme.color.text};
  background: ${defaultTheme.color.textInverted};
  color: ${defaultTheme.color.text};
`

const Row = styled.div`
  display: flex;

  > * {
    padding-right: 0.5em;
    padding-left: 0.5em;
    flex: 1;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL("image/jpeg", .9))
    }
  })
}

const defaultColor = '#ffffff'

class CreateCard extends React.Component {
  state = {
    name: '',
    description: '',
    nameFromProps: false,
    color: '#ffffff',
    images: [],
  }

  constructor(props) {
    super(props)
    if (this.props.name && this.props.name !== '') {
      this.state.name = this.props.name
      this.state.nameFromProps = true
    }
  }

  handleInputChange = (name) => (event) => {
    this.setState({[name]: event.target.value})
  }

  handleImageChange = async (value) => {
    const initSize = value[0].data_url.length
    const smallerImage = await resizeImage(value[0].data_url, 720, 1280)
    let smallerImages = value
    if (initSize > smallerImage.length) {
      smallerImages = [{...value[0], data_url: smallerImage}]
    }
    this.setState({images: smallerImages})
  }

  handleSubmit = (event) => {
    let {name, description, color, images} = this.state
    const {markerPos} = this.props
    let image = ''
    if (images.length) {
      image = images[0]
    }
    if (color === defaultColor) {
      color = null
    }
    this.props.onSave({name, description, lat: markerPos.lat, lng: markerPos.lng, color, image})
    event.preventDefault()
  }

  handleClose = () => {
    this.setState({
      name: '',
      description: '',
      color: defaultColor,
      images: [],
    })
    this.props.onClose()
  }

  render() {
    const {markerPos, onSave, onClose, name, ...props} = this.props
    return (
      <Card {...props}>
        <Wrapper>
          <Title>Neuer Schiss</Title>
          <Label>Name</Label>
          <Input
            onChange={this.handleInputChange('name')}
            value={this.state.name}
            disabled={this.state.nameFromProps}
          />
          <Label>Beschreibung</Label>
          <Textarea onChange={this.handleInputChange('description')} value={this.state.description}/>
          <Row>
            <div>
              <Label>Farbe</Label>
              <ColorPicker type="color" onChange={this.handleInputChange('color')} value={this.state.color}/>
            </div>
            <div>
              <Label>Panorama</Label>
              <ImageUpload value={this.state.images} onChange={this.handleImageChange}/>
            </div>
          </Row>
          <ButtonLine>
            <Abort type="button" value={'Fehlalarm'} onClick={this.handleClose}/>
            <Submit type="submit" value={'Aktivieren'} onClick={this.handleSubmit}/>
          </ButtonLine>
        </Wrapper>
      </Card>
    )
  }
}

CreateCard.defaultProps = {
  markerPos: {
    lat: 0,
    lng: 0,
  },
  onSave: () => {
  },
  onClose: () => {
  },
}

export default CreateCard
