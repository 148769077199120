import React from 'react'
import styled from 'styled-components'
import GoogleMapReact, { fitBounds } from 'google-map-react'
import Marker from './components/Marker'
import ClusterMarker from './components/ClusterMarker'
import supercluster from 'points-cluster'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

class Map extends React.Component {
  state = {
    gotGeoLoc: false,
    center: {
      lat: 42.3,
      lng: 13.0,
    },
    zoom: 6,
    key: 0,
    clusters: [],
  }

  center = {
    lat: 42.3,
    lng: 13.0,
  }
  bounds = {}

  getCenterLoc = () => {
    return this.center
  }

  setCenter = () => {
    const { center, zoom } = this.getFurthestBounds()
    this.setState({ center, zoom })
  }

  onMarkerInteraction = (a, b, mouse) => {
    this.props.onMapClick(mouse)
  }

  getFurthestBounds = () => {
    const lats = this.props.places.map(({ lat }) => lat * 1)
    const lngs = this.props.places.map(({ lng }) => lng * 1)
    const nw = {
      lat: Math.max(...lats),
      lng: Math.min(...lngs),
    }
    const se = {
      lat: Math.min(...lats) - 0.000000000001,
      lng: Math.max(...lngs) + 0.000000000001,
    }
    let { center, zoom } = fitBounds({ nw, se }, { width: window.innerWidth - 40, height: window.innerHeight - 40 })
    zoom = zoom ? Math.min(zoom, 18) : 18
    return { center, zoom }
  }

  onChange = ({ zoom, center, bounds }) => {
    this.center = center
    this.zoom = zoom
    this.setState({ bounds })
  }

  createClusters = () => {
    if (!this.state.bounds) {
      return []
    }
    const cl = supercluster(this.props.places, {
      maxZoom: 20,
      radius: 15,
    })

    const r = cl({ bounds: this.state.bounds, zoom: this.zoom })
    return r.map(({ wx, wy, numPoints, points }) => {
      return {
        lat: wy,
        lng: wx,
        numPoints,
        id: points[0].id,
        points,
        unread: points.reduce((accumulator, point) => (point.unread ? accumulator + 1 : accumulator), 0),
      }
    })
  }

  render() {
    const { places, onPlaceClick, onMapClick, ...props } = this.props
    const cluster = this.createClusters()
    return (
      <Wrapper {...props}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBBDbLxjEE5z7X_GCYzgNB6aRFaTqiYT8Q' }}
          zoom={this.state.zoom}
          key={this.state.key}
          center={this.state.center}
          onClick={onMapClick}
          draggable={true}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseMove={this.onMarkerInteraction}
          onChange={this.onChange}
          options={{ mapTypeId: 'hybrid' }}
        >
          {cluster.map(({ id, lat, lng, points, numPoints, unread }, index) => {
            if (numPoints === 1) {
              return <Marker key={id + '' + index} lat={lat} lng={lng} onClick={onPlaceClick(id)} unread={unread} />
            }

            return <ClusterMarker key={id + '' + index} lat={lat} lng={lng} amount={numPoints} unread={unread} />
          })}
        </GoogleMapReact>
      </Wrapper>
    )
  }
}

Map.defaultProps = {
  places: [],
  onPlaceClick: () => () => {},
  onMapClick: () => {},
}

export default Map
