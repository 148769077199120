import styled from 'styled-components'
import React from 'react'
import ImageUploading from 'react-images-uploading'
import defaultTheme from "../../../../../../themes/default";

const Wrapper = styled.div`
  margin-bottom: .5em;
  position:relative;
`

const Button = styled.span`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.primary};
  border: ${defaultTheme.color.primary} 2px solid;
  border-radius: 2em;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
`

const Img = styled.img`
  max-width: 100%;
`

const Remove = styled.div`
  position: absolute;
  top: .2em;
  left: .5em;
  color: ${defaultTheme.color.text};
  font-size: 1.5em;
  font-family: ${defaultTheme.font.primary};
  cursor: pointer;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10em;
  height: 1.5em;
  width: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

class ImageUpload extends React.Component {
  render() {
    const {value, onChange, ...props} = this.props
    return (
      <ImageUploading {...props} value={value} onChange={onChange} maxNumber={1} dataURLKey="data_url">
        {({imageList, onImageUpload, onImageUpdate, onImageRemove}) => (
          <div>
            {!imageList.length && (
              <Button onClick={onImageUpload}>
                Bild hinzufügen
              </Button>
            )}
            {imageList.map((image, index) => (
              <Wrapper key={index} className="image-item"  onClick={() => onImageUpdate(index)}>
                <Img src={image['data_url']} alt=""/>
                <Remove onClick={() => onImageRemove(index)}>✖</Remove>
              </Wrapper>
            ))}
          </div>
        )}
      </ImageUploading>
    )
  }
}

export default ImageUpload
