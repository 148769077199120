const defaultTheme = {
  color: {
    primary: '#3BA8EB',
    text: 'rgba(0, 0, 0, .65)',
    textSecondary: 'rgba(0, 0, 0, .35)',
    textInverted: '#fff',
  },
  font: {
    primary: '"Nunito", sans-serif',
  },
  fontSize: {
    small: '0.9rem',
    text: '1.2rem',
    big: '1.5em',
  },
  fontWeight: {
    bold: 700,
    black: 900,
  },
  lineHeight: {
    title: 1,
    text: 1.5,
  },
}

export default defaultTheme
