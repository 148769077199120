import React from 'react'
import styled from 'styled-components'
import defaultTheme from '../../../../themes/default'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import { ReactComponent as PoopMarker } from '../../../../images/poop-marker.svg'
 import Clap from './components/Clap'
import Card from '../Card'
import * as PropTypes from "prop-types";
import Remove from "./components/Remove";

dayjs.extend(LocalizedFormat)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingLine = styled.div`
  display: flex;
  justify-content: center;
`

// const Id = styled.span`
//   font-family: ${defaultTheme.font.primary};
//   font-weight: ${defaultTheme.fontWeight.black};
//   font-size: ${defaultTheme.fontSize.big};
//   color: ${defaultTheme.color.primary};
// `

const Title = styled.h1`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.black};
  font-size: ${defaultTheme.fontSize.big};
  color: ${defaultTheme.color.text};
  display: inline-block;
  padding-left: 0.5em;
  margin: 0 0 .2em;
`

const Date = styled.p`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.primary};
  display: block;
  text-align: center;
  margin: 0;
`

const Description = styled.p`
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.text};
  display: flex;
  align-items: center;
  margin: 0.5em 0 0;
`

const StyledPoopMarker = styled(PoopMarker)`
  height: 1.5em;
  fill: ${defaultTheme.color.primary};
  padding-right: 0.5em;
  display: inline-block;
`
const Close = styled.span`
  position: absolute;
  top: 0.2em;
  right: 0.5em;
  color:rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, .5);
  font-size: 1.5em;
  border-radius: 2em;
  font-family: ${defaultTheme.font.primary};
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Color = styled.div`
  width: 100%;
  font-size: ${defaultTheme.fontSize.text};
  font-family: ${defaultTheme.font.primary};
  font-weight: ${defaultTheme.fontWeight.bold};
  font-size: ${defaultTheme.fontSize.text};
  color: ${defaultTheme.color.textInverted};
  background-color: ${(props) => props.color};
  height: 2em;
  padding: 0 .5em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: .5em;
  border-radius: .5em;
`

const BGImage = styled.div`
  background-position: center;
  background-image: url(${props => props.image});
  background-color: #f1f1f1;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  aspect-ratio: 4/3;
  max-height: 350px;
  border-radius: 1em;
  margin-bottom: .5em
`

const getLocationName = (location) => {
  if (location.administrative_area_level_3 && location.administrative_area_level_1) {
    return location.administrative_area_level_3 + ', ' + location.administrative_area_level_1
  }
  if (location.administrative_area_level_3) {
    return location.administrative_area_level_3
  }
  if (location.administrative_area_level_1) {
    return location.administrative_area_level_1
  }
  if (location.establishment) {
    return location.establishment
  }
  return JSON.stringify(location)
}

class ViewCard extends React.Component {
  handleClap = () => {
    let {id, name, clapName, onClap} = this.props
    if (name === clapName) return
    onClap({id, name, likeName: clapName})
  }
  handleRemove = () => {
    let {id, name, clapName, onRemove} = this.props
    if (name !== clapName) return
    onRemove({id, name})
  }

  render() {
    const {id, location, date, name, description, color, image, likes, onClap, onClose, onRemove, clapName, ...props} = this.props;
    return (
      <Card {...props}>
        <Wrapper data-id={id}>
          {image && <BGImage image={typeof image === 'string' ? image : image.data_url}/>}
          <HeadingLine>
            {/*<Id>#{id}</Id>*/}
            <Title>{name}</Title>
          </HeadingLine>
          <Date>{dayjs(date).locale('de').format('dd, DD. MMM YYYY - HH:mm [Uhr]')}</Date>
          <Description>
            <StyledPoopMarker/>
            {getLocationName(location)}
          </Description>
          <Description>{description}</Description>
          {color && <Color color={color}>Farbe</Color>}
          <Clap onClick={this.handleClap} claps={likes} disabled={name === clapName}/>
          {name === clapName && (
            <Remove onClick={this.handleRemove} />
          )}
          <Close onClick={onClose}>✖</Close>
        </Wrapper>
      </Card>
    );
  }
}

ViewCard.propTypes = {
  id: PropTypes.any,
  location: PropTypes.any,
  date: PropTypes.any,
  name: PropTypes.any,
  description: PropTypes.any,
  color: PropTypes.any,
  image: PropTypes.any,
  onClap: PropTypes.any,
  onClose: PropTypes.any
}


ViewCard.defaultProps = {
  onClap: () => {},
}

export default ViewCard
